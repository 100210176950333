<template>
	<v-card class="application-box">
		<v-card-title class="py-2">
			<div
				class="d-flex align-center justify-space-between"
				style="width: 100%"
			>
				<div>
					<v-icon color="primary">mdi-view-dashboard-outline</v-icon>
					<span class="primary--text pl-2">신청 현황</span>
				</div>

				<v-select
					:items="eventCategories"
					v-model="selected"
					item-text="name"
					item-value="value"
					hide-details
					@change="changeCategory"
					dense
					outlined
				/>
			</div>
		</v-card-title>
		<template v-if="events.length > 0">
			<v-virtual-scroll
				:items="events"
				height="800"
				:item-height="isMdAndUp ? 103 : 160"
			>
				<template v-slot:default="{ item }">
					<v-card
						class="application-card my-3"
						:class="isMdAndUp ? 'mx-5' : 'mx-2'"
						:height="isMdAndUp ? 96 : 150"
						:max-height="isMdAndUp ? 96 : 156"
					>
						<v-card-text
							class="d-flex"
							:class="isMdAndUp ? 'py-4' : 'px-3 pt-3 pb-1'"
						>
							<div class="left">
								<div class="application-status" v-if="isMdAndUp">
									<v-chip
										small
										rounded
										:color="
											resolveApplicationStatusVariant(
												item.marketEvent.category,
												item.status,
												item.retake,
												item.marketEvent.amount > 0 ? true : false,
											)
										"
										class="text-center mb-1"
									>
										{{
											resolveApplicationStatus(
												item.marketEvent.category,
												item.status,
												item.retake,
												item.marketEvent.amount > 0 ? true : false,
											)
										}}
									</v-chip>
									<v-chip
										small
										rounded
										color="primary"
										v-if="item && item.checkIn"
									>
										<span v-if="item && item.checkIn" class="mr-1">
											입실
											<v-icon x-small style="position: relative; bottom: 1px">
												mdi-check-circle
											</v-icon>
										</span>

										<span v-if="item && item.checkOut">
											퇴실
											<v-icon x-small style="position: relative; bottom: 1px">
												mdi-check-circle
											</v-icon>
										</span>
									</v-chip>
								</div>
								<div class="application-title">
									<strong class="text" :class="{ 'ml-3': isMdAndUp }">
										{{ item.marketEvent.title }}
									</strong>
									<v-chip
										small
										label
										v-if="
											isShowTestStatus(item.marketEvent.category, item.result)
										"
										:color="resolveTestStatusVariant(item.result)"
										class="ml-1"
									>
										{{ resolveTestStatus(item.result) }}
									</v-chip>
									<div class="text-sm" :class="{ 'ml-2': isMdAndUp }">
										<v-icon small>mdi-calendar-check-outline</v-icon>
										<span
											style="position: relative; top: 2px"
											v-if="item.marketEvent.eventAt"
										>
											{{ item.marketEvent.eventAt | dateSiFormat }}
										</span>
										<span style="position: relative; top: 2px" v-else>
											상시
										</span>
									</div>
									<div
										class="text-sm d-flex"
										:class="
											isMdAndUp ? 'ml-2 align-center' : 'align-start mt-1'
										"
									>
										<v-icon small color="info">mdi-map-marker</v-icon>

										<span class="secondary--text location-text">
											{{ item.marketEvent.location }}
										</span>
										<div
											v-if="
												(item.status === 'PAID' || item.status === 'APPLIED') &&
												[18, 20].includes(item.marketEvent.id)
											"
										>
											자리 선택
											<span v-if="item.seatNumber" class="ml-1">
												(
												<strong>{{ item.seatNumber }}</strong>
												번)
											</span>
										</div>
									</div>
								</div>
							</div>
							<div class="right">
								<div class="application-attendance">
									<div class="application-status mb-1" v-if="!isMdAndUp">
										<v-chip
											small
											rounded
											:color="
												resolveApplicationStatusVariant(
													item.marketEvent.category,
													item.status,
													item.retake,
													item.marketEvent.amount > 0 ? true : false,
												)
											"
											class="text-center"
										>
											{{
												resolveApplicationStatus(
													item.marketEvent.category,
													item.status,
													item.retake,
													item.marketEvent.amount > 0 ? true : false,
												)
											}}
										</v-chip>
										<v-chip
											small
											rounded
											color="primary"
											v-if="item && item.checkIn"
											class="ml-1"
										>
											<span v-if="item && item.checkIn" class="mr-1">
												입실
												<v-icon x-small style="position: relative; bottom: 1px">
													mdi-check-circle
												</v-icon>
											</span>

											<span v-if="item && item.checkOut">
												퇴실
												<v-icon x-small style="position: relative; bottom: 1px">
													mdi-check-circle
												</v-icon>
											</span>
										</v-chip>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</template>
			</v-virtual-scroll>
		</template>
		<template v-else>
			<div class="d-flex justify-center align-center">신청 정보가 없습니다</div>
		</template>
	</v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import {
	resolveApplicationStatus,
	resolveApplicationStatusVariant,
	resolveTestStatus,
	resolveTestStatusVariant,
} from '@core/utils/filter'
import { useRouter, getVuetify } from '@core/utils'
import { eventCategory, applicationStatus, testStatus } from '@/helpers'

import EventService from '@/services/EventService'

export default {
	setup() {
		const $vuetify = getVuetify()
		const { router } = useRouter()
		const events = ref([])
		const selected = ref('')
		const educationLicense = ref(eventCategory.EDUCATION_LICENSE.value)
		const education = ref(eventCategory.EDUCATION.value)
		const eventCategories = ref([
			{
				name: '전체',
				value: '',
			},
			{
				name: '자격증',
				value: 'LICENSE',
			},
			...Object.values(eventCategory).filter(
				e =>
					e.value !== eventCategory.EDUCATION_LICENSE.value &&
					e.value !== eventCategory.LICENSE_TEST.value,
			),
		])

		const getMeMarketEvents = async (category = '') => {
			try {
				const data = await EventService.getMeMarketEventParticipations(category)

				events.value = data
			} catch (e) {
				console.error(e)
			}
		}
		getMeMarketEvents()

		const changeCategory = async category => {
			if (category === 'LICENSE') {
				category = `${eventCategory.EDUCATION_LICENSE.value},${eventCategory.LICENSE_TEST.value}`
			}
			await getMeMarketEvents(category)
		}

		const isShowTestStatus = (category, result) => {
			const statuses = [testStatus.PASS.value, testStatus.FAIL.value]
			return (
				category === eventCategory.LICENSE_TEST.value &&
				statuses.includes(result)
			)
		}

		const routeToEvent = item => {
			let routerName = 'mypage-application-event'
			if (item.marketEvent.category === eventCategory.LICENSE_TEST.value) {
				routerName = 'mypage-application-event-test'
			}

			router.push({
				name: routerName,
				params: {
					eventId: item.marketEvent.id,
				},
			})
		}

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		return {
			events,
			selected,
			isMdAndUp,
			eventCategories,
			education,
			educationLicense,
			applicationStatus,

			resolveApplicationStatus,
			resolveApplicationStatusVariant,
			resolveTestStatus,
			resolveTestStatusVariant,

			changeCategory,
			isShowTestStatus,
			routeToEvent,
		}
	},
}
</script>

<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.v-select {
	max-width: 150px;
	min-width: 140px;
}

span.body-content {
	::v-deep {
		p {
			margin-bottom: 2px;
		}
	}
}

.application-box {
	overflow: scroll;
}

.application {
	&-card {
		position: relative;

		.v-card__text {
			display: flex;
			justify-content: space-between;

			.left {
				display: flex;
			}

			@media (max-width: 960px) {
				flex-direction: column-reverse;
			}
		}
	}

	&-status {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 109px;
		margin-right: 12px;
		padding-right: 16px;
		border-right: 1px solid #e6e6e6;

		span.v-chip {
			position: relative;
			top: 3px;
		}

		@media (max-width: 960px) {
			flex-direction: row;
			border-right: none;
			justify-content: start;
		}
	}

	&-title {
		position: relative;
		top: 2px;
		line-height: 20px;
		max-width: 500px;

		.location-text {
			position: relative;
			top: 2px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			@media (max-width: 960px) {
				top: 0px;
				white-space: pre-wrap;
			}
		}
	}

	&-attendance {
		@media (max-width: 960px) {
			display: flex;
			justify-content: space-between;
		}
	}
}
</style>
